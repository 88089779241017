"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var types_1 = require("./types");
var requests_1 = require("../../app/util/requests");
var setLinksToRotate = function (payload) { return ({ type: types_1.SET_LINKS_TO_ROTATE, payload: payload }); };
var getLinksToRotate = function () {
    return function (dispatch, getState) {
        var home = getState().home;
        var linksToRotate = home.linksToRotate;
        if (!linksToRotate) {
            (0, requests_1.get)('/api/links').then(function (response) {
                if (response.ok) {
                    response.text().then(function (extractedString) {
                        if (extractedString) {
                            var links = extractedString
                                .split(',')
                                .map(function (link) { return link.trim(); });
                            dispatch(setLinksToRotate(links));
                        }
                    });
                }
            });
        }
    };
};
var loadStats = function () { return function (dispatch) {
    (0, requests_1.get)('/api/stats').then(function (response) {
        if (response.ok) {
            response.json().then(function (stats) {
                dispatch({
                    type: types_1.LOAD_STATS,
                    payload: stats,
                });
            });
        }
    });
}; };
exports.default = {
    getLinksToRotate: getLinksToRotate,
    loadStats: loadStats,
};
