"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobStatusEnum = exports.JobItemStatusEnum = void 0;
var JobItemStatusEnum;
(function (JobItemStatusEnum) {
    JobItemStatusEnum["InProgress"] = "IN_PROGRESS";
    JobItemStatusEnum["Success"] = "SUCCESS";
    JobItemStatusEnum["Failure"] = "FAILURE";
})(JobItemStatusEnum = exports.JobItemStatusEnum || (exports.JobItemStatusEnum = {}));
var JobStatusEnum;
(function (JobStatusEnum) {
    JobStatusEnum["InProgress"] = "IN_PROGRESS";
    JobStatusEnum["Success"] = "SUCCESS";
    JobStatusEnum["Failure"] = "FAILURE";
})(JobStatusEnum = exports.JobStatusEnum || (exports.JobStatusEnum = {}));
