"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Specifies the list of sites shortened urls are prohibited from redirecting to.
exports.default = [
    'rebrand.ly',
    'bit.ly',
    'goo.gl',
    'tinyurl',
    'tiny.cc',
    'lc.chat',
    'is.gd',
    'soo.gd',
    's2r.co',
    'clicky.me',
    'bl.ink',
];
