"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
function BackIcon(_a) {
    var color = _a.color;
    var backSvg = (React.createElement("svg", { role: "img", xmlns: "http://www.w3.org/2000/svg", width: "16", height: "14" },
        React.createElement("title", { id: "svg1Title" }, "Back"),
        React.createElement("path", { fill: color, d: "M15.5 6.16729H3.34503L7.75586 1.75646L6.57753 0.578125L0.155029 7.00062L6.57753 13.4231L7.75586 12.2448L3.34503 7.83396H15.5V6.16729Z" })));
    return backSvg;
}
exports.default = BackIcon;
