"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IS_LOGGED_OUT = exports.IS_LOGGED_IN_SUCCESS = exports.SET_EMAIL_VALIDATOR = exports.RESEND_OTP_DISABLED = exports.RESEND_OTP_PENDING = exports.VERIFY_OTP_PENDING = exports.VERIFY_OTP_ERROR = exports.GET_OTP_EMAIL_ERROR = exports.GET_OTP_EMAIL_PENDING = exports.GET_OTP_EMAIL_SUCCESS = void 0;
exports.GET_OTP_EMAIL_SUCCESS = 'GET_OTP_EMAIL_SUCCESS';
exports.GET_OTP_EMAIL_PENDING = 'GET_OTP_EMAIL_PENDING';
exports.GET_OTP_EMAIL_ERROR = 'GET_OTP_EMAIL_ERROR';
exports.VERIFY_OTP_ERROR = 'VERIFY_OTP_ERROR';
exports.VERIFY_OTP_PENDING = 'VERIFY_OTP_PENDING';
exports.RESEND_OTP_PENDING = 'RESEND_OTP_PENDING';
exports.RESEND_OTP_DISABLED = 'RESEND_OTP_DISABLED';
exports.SET_EMAIL_VALIDATOR = 'SET_EMAIL_VALIDATOR';
exports.IS_LOGGED_IN_SUCCESS = 'IS_LOGGED_IN_SUCCESS';
exports.IS_LOGGED_OUT = 'IS_LOGGED_OUT';
