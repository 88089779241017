"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@material-ui/core");
var useSearchInputHeight = function () { return 48; };
exports.default = (0, core_1.makeStyles)(function (theme) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    return (0, core_1.createStyles)({
        collapse: (_a = {
                width: '100%',
                position: 'absolute',
                left: 0,
                top: useSearchInputHeight() + 10,
                zIndex: 1000
            },
            _a[theme.breakpoints.down('sm')] = {
                top: 0,
                height: '100% !important',
                minHeight: '800px !important',
            },
            _a),
        collapseWrapper: (_b = {},
            _b[theme.breakpoints.down('sm')] = {
                height: '100%',
            },
            _b),
        closeIcon: (_c = {
                position: 'absolute',
                top: 0,
                right: 0,
                margin: theme.spacing(1)
            },
            _c[theme.breakpoints.down('sm')] = {
                margin: theme.spacing(3),
            },
            _c),
        sortButtonGrid: {
            height: '67px',
            width: '100%',
        },
        sectionHeader: (_d = {
                paddingLeft: theme.spacing(4)
            },
            _d[theme.breakpoints.up('md')] = {
                color: '#767676',
            },
            _d),
        sortHeaderGrid: {
            marginBottom: theme.spacing(1),
        },
        filterHeaderGrid: (_e = {
                marginTop: theme.spacing(0.5),
                marginBottom: theme.spacing(1)
            },
            _e[theme.breakpoints.down('sm')] = {
                marginTop: theme.spacing(5.75),
            },
            _e),
        filterSectionGrid: {
            marginLeft: theme.spacing(4),
        },
        filterSectionHeader: {
            marginTop: theme.spacing(2.5),
            fontWeight: 500,
            marginBottom: theme.spacing(0.5),
        },
        filterLabelLeft: {
            fontWeight: 400,
            width: '100px',
        },
        filterLabelRight: {
            fontWeight: 400,
        },
        divider: {
            width: '100%',
        },
        dividerGrid: (_f = {
                marginBottom: theme.spacing(0.5)
            },
            _f[theme.breakpoints.down('sm')] = {
                marginBottom: theme.spacing(4),
            },
            _f),
        root: {
            boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)',
            border: 'solid 1px #e8e8e8',
            height: '100%',
            overflow: 'hidden',
        },
        leftCheckbox: {
            marginLeft: theme.spacing(-1.5),
        },
        sortButtonRoot: {
            borderRadius: 0,
        },
        sortButton: {
            height: '100%',
            justifyContent: 'start',
        },
        sortButtonSelected: {
            height: '100%',
            justifyContent: 'start',
            background: theme.palette.background.default,
        },
        columnLabel: {
            paddingLeft: theme.spacing(3),
            fontWeight: 400,
            flex: 1,
            textAlign: 'left',
        },
        checkIcon: {
            marginLeft: 'auto',
            marginRight: theme.spacing(4),
            flexShrink: 0,
            flexGrow: 0,
        },
        sortButtonLabel: {
            display: 'flex',
            alignItems: 'flex-start',
        },
        applyButton: (_g = {
                width: '121px',
                height: '45px'
            },
            _g[theme.breakpoints.down('sm')] = {
                width: '100%',
                height: '55px',
                marginRight: theme.spacing(4),
            },
            _g),
        footer: (_h = {
                padding: theme.spacing(2),
                marginRight: theme.spacing(4)
            },
            _h[theme.breakpoints.down('sm')] = {
                marginTop: theme.spacing(11),
                flexDirection: 'column-reverse',
            },
            _h),
        resetButton: (_j = {
                marginRight: theme.spacing(1.5)
            },
            _j[theme.breakpoints.down('sm')] = {
                width: '100%',
                height: '55px',
            },
            _j),
        buttonGrid: (_k = {},
            _k[theme.breakpoints.down('sm')] = {
                width: '100%',
            },
            _k),
        collapsingPanel: {
            width: '100%',
        },
    });
});
