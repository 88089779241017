"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var types_1 = require("../actions/types");
var initialState = {
    hasApiKey: false,
    apiKeyModal: false,
    apiKey: '',
};
var api = function (state, action) {
    if (state === void 0) { state = initialState; }
    var nextState = {};
    switch (action.type) {
        case types_1.USER_HAS_API_KEY:
            nextState = {
                hasApiKey: true,
            };
            break;
        case types_1.USER_HAS_NO_API_KEY:
            nextState = {
                hasApiKey: false,
            };
            break;
        case types_1.OPEN_API_KEY_MODAL:
            nextState = {
                apiKeyModal: true,
            };
            break;
        case types_1.CLOSE_API_KEY_MODAL:
            nextState = {
                apiKeyModal: false,
                apiKey: '',
            };
            break;
        case types_1.GENERATE_API_KEY_SUCCESSFULLY:
            nextState = {
                apiKey: action.payload.apiKey,
                hasApiKey: true,
            };
            break;
        default:
            return state;
    }
    return __assign(__assign({}, state), nextState);
};
exports.default = api;
