"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Available formats for download.
var ImageFormat;
(function (ImageFormat) {
    ImageFormat["SVG"] = "image/svg+xml";
    ImageFormat["PNG"] = "image/png";
    ImageFormat["JPEG"] = "image/jpeg";
})(ImageFormat || (ImageFormat = {}));
exports.default = ImageFormat;
