"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialState = void 0;
var types_1 = require("../actions/types");
exports.initialState = {
    results: [],
    resultsCount: 0,
    queryForResult: null,
};
var directory = function (state, action) {
    if (state === void 0) { state = exports.initialState; }
    var nextState = {};
    switch (action.type) {
        case types_1.SET_DIRECTORY_RESULTS:
            nextState = {
                resultsCount: action.payload.count,
                results: action.payload.urls,
                queryForResult: action.payload.query,
            };
            break;
        case types_1.SET_INITIAL_STATE:
            nextState = exports.initialState;
            break;
        default:
            break;
    }
    return __assign(__assign({}, state), nextState);
};
exports.default = directory;
