"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@material-ui/core");
exports.default = (0, core_1.makeStyles)(function (theme) {
    var _a, _b, _c;
    return (0, core_1.createStyles)({
        toolbar: {
            paddingLeft: function (props) { return props.appMargins; },
            paddingRight: function (props) { return props.appMargins; },
        },
        spacer: {
            flex: 0,
        },
        caption: (_a = {
                fontWeight: 400,
                marginRight: '4px'
            },
            _a[theme.breakpoints.down('sm')] = {
                display: 'none',
            },
            _a),
        select: (_b = {
                border: 'solid 1px #d8d8d8',
                zIndex: 2
            },
            _b[theme.breakpoints.down('sm')] = {
                display: 'none',
            },
            _b),
        selectIcon: (_c = {
                zIndex: 2
            },
            _c[theme.breakpoints.down('sm')] = {
                display: 'none',
            },
            _c),
    });
});
