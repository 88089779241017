"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusBarVariant = exports.SortDirection = exports.UrlState = void 0;
var UrlState;
(function (UrlState) {
    UrlState["Active"] = "ACTIVE";
    UrlState["Inactive"] = "INACTIVE";
})(UrlState = exports.UrlState || (exports.UrlState = {}));
var SortDirection;
(function (SortDirection) {
    SortDirection["Descending"] = "desc";
    SortDirection["Ascending"] = "asc";
})(SortDirection = exports.SortDirection || (exports.SortDirection = {}));
var StatusBarVariant;
(function (StatusBarVariant) {
    StatusBarVariant["Success"] = "SUCCESS";
    StatusBarVariant["Error"] = "ERROR";
    StatusBarVariant["Info"] = "INFO";
})(StatusBarVariant = exports.StatusBarVariant || (exports.StatusBarVariant = {}));
