"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultSortOption = exports.sortOptions = void 0;
var search_1 = require("../../shared/search");
/**
 * The available sorting options for GoDirectory which includes popularity and recency.
 */
exports.sortOptions = [
    { key: search_1.SearchResultsSortOrder.Popularity, label: 'Most popular' },
    { key: search_1.SearchResultsSortOrder.Recency, label: 'Most recent' },
];
exports.defaultSortOption = search_1.SearchResultsSortOrder.Recency;
exports.default = { sortOptions: exports.sortOptions, defaultSortOption: exports.defaultSortOption };
