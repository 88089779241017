"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var types_1 = require("../../../../util/types");
var types_2 = require("../actions/types");
var initialState = {
    snackbarMessage: {
        message: '',
        variant: types_1.snackbarVariants.ERROR,
    },
};
var root = function (state, action) {
    if (state === void 0) { state = initialState; }
    var nextState = {};
    switch (action.type) {
        case types_2.CLOSE_SNACKBAR:
            nextState = __assign(__assign({}, state), { snackbarMessage: {
                    message: '',
                    variant: state.snackbarMessage.variant,
                } });
            break;
        case types_2.SET_SUCCESS_MESSAGE:
            nextState = {
                snackbarMessage: {
                    message: action.payload,
                    variant: types_1.snackbarVariants.SUCCESS,
                },
            };
            break;
        case types_2.SET_ERROR_MESSAGE:
            nextState = {
                snackbarMessage: {
                    message: action.payload,
                    variant: types_1.snackbarVariants.ERROR,
                },
            };
            break;
        case types_2.SET_INFO_MESSAGE:
            nextState = {
                snackbarMessage: {
                    message: action.payload,
                    variant: types_1.snackbarVariants.INFO,
                },
            };
            break;
        default:
            return state;
    }
    return __assign(__assign({}, state), nextState);
};
exports.default = root;
