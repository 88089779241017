"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BULK_QR_DOWNLOAD_MAPPINGS = exports.BULK_QR_DOWNLOAD_FORMATS = exports.MIN_TAG_SEARCH_LENGTH = exports.MAX_NUM_TAGS_PER_LINK = exports.TAG_SEPARATOR = exports.BULK_UPLOAD_HEADER = exports.LINK_DESCRIPTION_MAX_LENGTH = exports.MAX_FILE_UPLOAD_SIZE = exports.MAX_CSV_UPLOAD_SIZE = void 0;
exports.MAX_CSV_UPLOAD_SIZE = 5 * 1024 * 1024; // 5 MB
exports.MAX_FILE_UPLOAD_SIZE = 20 * 1024 * 1024; // 20 MB
exports.LINK_DESCRIPTION_MAX_LENGTH = 200;
exports.BULK_UPLOAD_HEADER = 'Original links to be shortened';
exports.TAG_SEPARATOR = ';';
exports.MAX_NUM_TAGS_PER_LINK = 3;
exports.MIN_TAG_SEARCH_LENGTH = 3;
var BULK_QR_DOWNLOAD_FORMATS;
(function (BULK_QR_DOWNLOAD_FORMATS) {
    BULK_QR_DOWNLOAD_FORMATS["CSV"] = "CSV";
    BULK_QR_DOWNLOAD_FORMATS["PNG"] = "PNG";
    BULK_QR_DOWNLOAD_FORMATS["SVG"] = "SVG";
})(BULK_QR_DOWNLOAD_FORMATS = exports.BULK_QR_DOWNLOAD_FORMATS || (exports.BULK_QR_DOWNLOAD_FORMATS = {}));
exports.BULK_QR_DOWNLOAD_MAPPINGS = {
    CSV: 'generated.csv',
    PNG: 'generated_png.zip',
    SVG: 'generated_svg.zip',
};
