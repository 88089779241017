"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loginFormVariants = exports.snackbarVariants = exports.API_INTEGRATION_PAGE = exports.DIRECTORY_PAGE = exports.NOT_FOUND_PAGE = exports.SEARCH_PAGE = exports.USER_PAGE = exports.SGID_LOGIN_PAGE = exports.LOGIN_PAGE = exports.HOME_PAGE = void 0;
exports.HOME_PAGE = '/';
exports.LOGIN_PAGE = '/login';
exports.SGID_LOGIN_PAGE = '/ogp-login';
exports.USER_PAGE = '/user';
exports.SEARCH_PAGE = '/search';
exports.NOT_FOUND_PAGE = '/404/:shortUrl';
exports.DIRECTORY_PAGE = '/directory';
exports.API_INTEGRATION_PAGE = '/apiintegration';
exports.snackbarVariants = { ERROR: 0, INFO: 1, SUCCESS: 2 };
var FormState;
(function (FormState) {
    FormState["EMAIL_READY"] = "EMAIL_READY";
    FormState["EMAIL_PENDING"] = "EMAIL_PENDING";
    FormState["OTP_READY"] = "OTP_READY";
    FormState["OTP_PENDING"] = "OTP_PENDING";
    FormState["RESEND_OTP_DISABLED"] = "RESEND_OTP_DISABLED";
})(FormState || (FormState = {}));
exports.loginFormVariants = {
    types: FormState,
    map: {
        EMAIL_READY: {
            inputEnabled: true,
            submitEnabled: true,
            progressBarShown: false,
            resendEnabled: false,
        },
        EMAIL_PENDING: {
            inputEnabled: false,
            submitEnabled: false,
            progressBarShown: true,
            resendEnabled: false,
        },
        OTP_READY: {
            inputEnabled: true,
            submitEnabled: true,
            progressBarShown: false,
            resendEnabled: true,
        },
        OTP_PENDING: {
            inputEnabled: false,
            submitEnabled: false,
            progressBarShown: true,
            resendEnabled: false,
        },
        RESEND_OTP_DISABLED: {
            inputEnabled: true,
            submitEnabled: true,
            progressBarShown: false,
            resendEnabled: false,
        },
    },
    isEmailView: function (variant) {
        return (variant === this.types.EMAIL_PENDING || variant === this.types.EMAIL_READY);
    },
};
