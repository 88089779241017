"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollapsibleMessagePosition = exports.CollapsibleMessageType = void 0;
var CollapsibleMessageType;
(function (CollapsibleMessageType) {
    CollapsibleMessageType[CollapsibleMessageType["Error"] = 0] = "Error";
    CollapsibleMessageType[CollapsibleMessageType["Success"] = 1] = "Success";
})(CollapsibleMessageType = exports.CollapsibleMessageType || (exports.CollapsibleMessageType = {}));
var CollapsibleMessagePosition;
(function (CollapsibleMessagePosition) {
    CollapsibleMessagePosition["Static"] = "static";
    CollapsibleMessagePosition["Absolute"] = "absolute";
})(CollapsibleMessagePosition = exports.CollapsibleMessagePosition || (exports.CollapsibleMessagePosition = {}));
