"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var browser_rum_1 = require("@datadog/browser-rum");
var environment_variables_1 = require("../../../shared/util/environment-variables");
var initMonitoring = function () {
    browser_rum_1.datadogRum.init({
        applicationId: '898ea704-7347-45dc-b40c-bf85359e062e',
        clientToken: 'pub40fb07aa43d3f6f034d8fcc7f1df867b',
        site: 'datadoghq.com',
        service: environment_variables_1.ddService,
        env: environment_variables_1.ddEnv,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingOrigins: [
            'https://go.gov.sg',
            'https://for.edu.sg',
            'https://for.sg',
            'https://staging.go.gov.sg',
            'https://staging.for.edu.sg',
            'https://staging.for.sg',
        ],
    });
    browser_rum_1.datadogRum.startSessionReplayRecording();
};
exports.default = initMonitoring;
