"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var queryObjFromTableConfig = function (tableConfig) {
    var numberOfRows = tableConfig.numberOfRows, pageNumber = tableConfig.pageNumber, sortDirection = tableConfig.sortDirection, orderBy = tableConfig.orderBy, searchText = tableConfig.searchText, tags = tableConfig.tags, _a = tableConfig.filter, urlState = _a.state, isFile = _a.isFile;
    var offset = pageNumber * numberOfRows;
    var queryObj = __assign(__assign({ limit: numberOfRows, offset: offset, orderBy: orderBy, sortDirection: sortDirection }, (urlState && { state: urlState })), { isFile: isFile, searchText: searchText, tags: tags });
    return queryObj;
};
exports.default = queryObjFromTableConfig;
