"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserAction = void 0;
/* User actions */
var UserAction;
(function (UserAction) {
    UserAction["CREATE_URL_SUCCESS"] = "CREATE_URL_SUCCESS";
    UserAction["GET_URLS_FOR_USER_SUCCESS"] = "GET_URLS_FOR_USER_SUCCESS";
    UserAction["OPEN_CREATE_URL_MODAL"] = "OPEN_CREATE_URL_MODAL";
    UserAction["CLOSE_CREATE_URL_MODAL"] = "CLOSE_CREATE_URL_MODAL";
    UserAction["SET_SHORT_URL"] = "SET_SHORT_URL";
    UserAction["SET_LONG_URL"] = "SET_LONG_URL";
    UserAction["SET_EDITED_LONG_URL"] = "SET_EDITED_LONG_URL";
    UserAction["SET_RANDOM_SHORT_URL"] = "SET_RANDOM_SHORT_URL";
    UserAction["RESET_USER_STATE"] = "RESET_USER_STATE";
    UserAction["TOGGLE_URL_STATE_SUCCESS"] = "TOGGLE_URL_STATE_SUCCESS";
    UserAction["SET_URL_TABLE_CONFIG"] = "SET_URL_TABLE_CONFIG";
    UserAction["SET_URL_FILTER"] = "SET_URL_FILTER";
    UserAction["UPDATE_URL_COUNT"] = "UPDATE_URL_COUNT";
    UserAction["IS_FETCHING_URLS"] = "IS_FETCHING_URLS";
    UserAction["WIPE_USER_STATE"] = "WIPE_USER_STATE";
    UserAction["SET_IS_UPLOADING"] = "SET_IS_UPLOADING";
    UserAction["SET_UPLOAD_FILE_ERROR"] = "SET_UPLOAD_FILE_ERROR";
    UserAction["SET_CREATE_SHORT_LINK_ERROR"] = "SET_CREATE_SHORT_LINK_ERROR";
    UserAction["SET_LAST_CREATED_LINK"] = "SET_LAST_CREATED_LINK";
    UserAction["SET_EDITED_CONTACT_EMAIL"] = "SET_EDITED_CONTACT_EMAIL";
    UserAction["SET_EDITED_DESCRIPTION"] = "SET_EDITED_DESCRIPTION";
    UserAction["SET_USER_MESSAGE"] = "SET_USER_MESSAGE";
    UserAction["SET_USER_ANNOUNCEMENT"] = "SET_USER_ANNOUNCEMENT";
    UserAction["SET_URL_UPLOAD_STATE"] = "SET_URL_UPLOAD_STATE";
    UserAction["SET_FILE_UPLOAD_STATE"] = "SET_FILE_UPLOAD_STATE";
    UserAction["GET_LINK_HISTORY_FOR_USER_SUCCESS"] = "GET_LINK_HISTORY_FOR_USER_SUCCESS";
    UserAction["SET_TAGS"] = "SET_TAGS";
    UserAction["CLOSE_STATUS_BAR"] = "CLOSE_STATUS_BAR";
    UserAction["SET_STATUS_BAR_ERROR_MESSAGE"] = "SET_STATUS_BAR_ERROR_MESSAGE";
    UserAction["SET_STATUS_BAR_INFO_MESSAGE"] = "SET_STATUS_BAR_INFO_MESSAGE";
    UserAction["SET_STATUS_BAR_SUCCESS_MESSAGE"] = "SET_STATUS_BAR_SUCCESS_MESSAGE";
})(UserAction = exports.UserAction || (exports.UserAction = {}));
