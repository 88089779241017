"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var types_1 = require("../actions/types");
var types_2 = require("./types");
var constants_1 = require("../constants");
var initialState = {
    initialised: false,
    urls: [],
    isFetchingUrls: false,
    isUploading: false,
    shortUrl: '',
    longUrl: '',
    tags: [],
    createUrlModal: false,
    tableConfig: __assign({ isTag: false, numberOfRows: 10, pageNumber: 0, searchText: '', searchInput: '', tags: '', filter: {} }, constants_1.initialSortConfig),
    urlCount: 0,
    message: null,
    announcement: null,
    uploadFileError: null,
    createShortLinkError: null,
    uploadState: {
        urlUpload: false,
        fileUpload: false,
    },
    linkHistory: [],
    linkHistoryCount: 0,
    statusBarMessage: {
        header: '',
        body: '',
        callbacks: [],
        variant: types_2.StatusBarVariant.Error,
    },
};
var user = function (state, action) {
    if (state === void 0) { state = initialState; }
    var nextState = {};
    switch (action.type) {
        case types_1.UserAction.SET_USER_MESSAGE:
            nextState = {
                message: action.payload,
            };
            break;
        case types_1.UserAction.SET_USER_ANNOUNCEMENT:
            nextState = {
                announcement: action.payload,
            };
            break;
        case types_1.UserAction.SET_LAST_CREATED_LINK:
            nextState = {
                lastCreatedLink: action.payload,
            };
            break;
        case types_1.UserAction.SET_CREATE_SHORT_LINK_ERROR:
            nextState = {
                createShortLinkError: action.payload,
            };
            break;
        case types_1.UserAction.SET_UPLOAD_FILE_ERROR:
            nextState = {
                uploadFileError: action.payload,
            };
            break;
        case types_1.UserAction.SET_IS_UPLOADING:
            nextState = {
                isUploading: action.payload,
            };
            break;
        case types_1.UserAction.IS_FETCHING_URLS:
            nextState = {
                isFetchingUrls: action.payload,
            };
            break;
        case types_1.UserAction.GET_URLS_FOR_USER_SUCCESS:
            nextState = {
                initialised: true,
                urls: action.payload,
            };
            break;
        case types_1.UserAction.SET_SHORT_URL:
            nextState = {
                shortUrl: action.payload,
            };
            break;
        case types_1.UserAction.SET_LONG_URL:
            nextState = {
                longUrl: action.payload,
            };
            break;
        case types_1.UserAction.SET_EDITED_LONG_URL: {
            var _a = action.payload, editedLongUrl_1 = _a.editedLongUrl, shortUrl_1 = _a.shortUrl;
            nextState = {
                urls: state.urls.map(function (url) {
                    if (shortUrl_1 !== url.shortUrl) {
                        return url;
                    }
                    return __assign(__assign({}, url), { editedLongUrl: editedLongUrl_1 });
                }),
            };
            break;
        }
        case types_1.UserAction.SET_EDITED_CONTACT_EMAIL: {
            var _b = action.payload, editedContactEmail_1 = _b.editedContactEmail, shortUrl_2 = _b.shortUrl;
            nextState = {
                urls: state.urls.map(function (url) {
                    if (shortUrl_2 !== url.shortUrl) {
                        return url;
                    }
                    return __assign(__assign({}, url), { editedContactEmail: editedContactEmail_1 });
                }),
            };
            break;
        }
        case types_1.UserAction.SET_EDITED_DESCRIPTION: {
            var _c = action.payload, editedDescription_1 = _c.editedDescription, shortUrl_3 = _c.shortUrl;
            nextState = {
                urls: state.urls.map(function (url) {
                    if (shortUrl_3 !== url.shortUrl) {
                        return url;
                    }
                    return __assign(__assign({}, url), { editedDescription: editedDescription_1 });
                }),
            };
            break;
        }
        case types_1.UserAction.SET_RANDOM_SHORT_URL:
            nextState = {
                shortUrl: action.payload,
            };
            break;
        case types_1.UserAction.RESET_USER_STATE:
            nextState = {
                shortUrl: '',
                longUrl: '',
                tags: [],
            };
            break;
        case types_1.UserAction.WIPE_USER_STATE:
            nextState = __assign({}, initialState);
            break;
        case types_1.UserAction.TOGGLE_URL_STATE_SUCCESS: {
            var _d = action.payload, shortUrl_4 = _d.shortUrl, toState_1 = _d.toState;
            nextState = {
                urls: state.urls.map(function (url) {
                    if (shortUrl_4 !== url.shortUrl) {
                        return url;
                    }
                    return __assign(__assign({}, url), { state: toState_1 });
                }),
            };
            break;
        }
        case types_1.UserAction.OPEN_CREATE_URL_MODAL:
            nextState = {
                createUrlModal: true,
            };
            break;
        case types_1.UserAction.CLOSE_CREATE_URL_MODAL:
            nextState = {
                createUrlModal: false,
            };
            break;
        case types_1.UserAction.SET_URL_TABLE_CONFIG:
            nextState = {
                tableConfig: __assign(__assign({}, state.tableConfig), action.payload),
            };
            break;
        case types_1.UserAction.SET_URL_FILTER:
            nextState = {
                tableConfig: __assign(__assign({}, state.tableConfig), { filter: action.payload, pageNumber: 0 }),
            };
            break;
        case types_1.UserAction.UPDATE_URL_COUNT:
            nextState = {
                urlCount: action.payload,
            };
            break;
        case types_1.UserAction.SET_URL_UPLOAD_STATE:
            nextState = {
                uploadState: __assign(__assign({}, state.uploadState), { urlUpload: action.payload }),
            };
            break;
        case types_1.UserAction.SET_FILE_UPLOAD_STATE:
            nextState = {
                uploadState: __assign(__assign({}, state.uploadState), { fileUpload: action.payload }),
            };
            break;
        case types_1.UserAction.GET_LINK_HISTORY_FOR_USER_SUCCESS:
            nextState = {
                linkHistory: action.payload.linkHistory,
                linkHistoryCount: action.payload.totalCount,
            };
            break;
        case types_1.UserAction.SET_TAGS:
            nextState = {
                tags: action.payload,
            };
            break;
        case types_1.UserAction.CLOSE_STATUS_BAR:
            nextState = __assign(__assign({}, state), { statusBarMessage: {
                    header: '',
                    body: '',
                    variant: state.statusBarMessage.variant,
                    callbacks: [],
                } });
            break;
        case types_1.UserAction.SET_STATUS_BAR_SUCCESS_MESSAGE:
            nextState = {
                statusBarMessage: {
                    header: action.payload.header,
                    body: action.payload.body,
                    callbacks: action.payload.callbacks,
                    variant: types_2.StatusBarVariant.Success,
                },
            };
            break;
        case types_1.UserAction.SET_STATUS_BAR_ERROR_MESSAGE:
            nextState = {
                statusBarMessage: {
                    header: action.payload.header,
                    body: action.payload.body,
                    callbacks: action.payload.callbacks,
                    variant: types_2.StatusBarVariant.Error,
                },
            };
            break;
        case types_1.UserAction.SET_STATUS_BAR_INFO_MESSAGE:
            nextState = {
                statusBarMessage: {
                    header: action.payload.header,
                    body: action.payload.body,
                    variant: types_2.StatusBarVariant.Info,
                    callbacks: [],
                },
            };
            break;
        default:
            return state;
    }
    return __assign(__assign({}, state), nextState);
};
exports.default = user;
