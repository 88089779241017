"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SEARCH_TIMEOUT = exports.TEXT_FIELD_HEIGHT = exports.initialSortConfig = void 0;
var types_1 = require("./reducers/types");
exports.initialSortConfig = {
    orderBy: 'createdAt',
    sortDirection: types_1.SortDirection.Descending,
};
exports.TEXT_FIELD_HEIGHT = 44;
// Search timeout in ms
exports.SEARCH_TIMEOUT = 500;
