"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var types_1 = require("./types");
var closeSnackbar = function () { return ({
    type: types_1.CLOSE_SNACKBAR,
}); };
var setErrorMessage = function (message) { return ({
    type: types_1.SET_ERROR_MESSAGE,
    payload: message,
}); };
var setInfoMessage = function (message) { return ({
    type: types_1.SET_INFO_MESSAGE,
    payload: message,
}); };
var setSuccessMessage = function (message) { return ({
    type: types_1.SET_SUCCESS_MESSAGE,
    payload: message,
}); };
exports.default = {
    closeSnackbar: closeSnackbar,
    setErrorMessage: setErrorMessage,
    setInfoMessage: setInfoMessage,
    setSuccessMessage: setSuccessMessage,
};
