"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@material-ui/core");
var useAppMargins = function () {
    var theme = (0, core_1.useTheme)();
    var xsWidth = (0, core_1.useMediaQuery)(theme.breakpoints.only('xs'));
    var smWidth = (0, core_1.useMediaQuery)(theme.breakpoints.only('sm'));
    var mdWidth = (0, core_1.useMediaQuery)(theme.breakpoints.only('md'));
    if (xsWidth) {
        return theme.spacing(4);
    }
    if (smWidth) {
        return theme.spacing(8);
    }
    if (mdWidth) {
        return theme.spacing(12);
    }
    return theme.spacing(16);
};
exports.default = useAppMargins;
